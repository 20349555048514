<template>
  <div class="wrap">
<!--    <div class="header">
      售后消息
    </div>-->
    <div class="list">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
      >
        <div class="item" v-for="message in messages" :key="message.orderId" @click="toMessageDetailPage(message.orderId, message.oUserId)">
          <div class="image">
            <img v-if="message.order && message.order.goods && message.order.goods.length" :src="message.order.goods[0].image" alt="">
            <div class="num" v-if="!message.lastReply && message.noReplyNum">{{ message.noReplyNum }}</div>
          </div>
          <div class="info">
            <div class="top">
              <div class="customer-name">
                {{ message.customerName }}
              </div>
              <div class="last-date">
                {{ message.lastMessageDate }}
              </div>
            </div>
            <div class="bottom">
              <div class="title">
                {{ message.lastText }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import {getMessageList} from "@/api/message";

export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      messages: [],
      loading: false,
      finished: false,
    }
  },
  created() {

  },
  mounted() {
    if (parseInt(this.$route.query.handle) === 1) {
      document.title = '已回复的售后消息';
    }else if (parseInt(this.$route.query.handle) === -1) {
      document.title = '未回复的售后消息';
    } else {
      document.title = '全部售后消息';
    }
  },
  methods: {
    getData() {
      const handle = this.$route.query.handle;
      getMessageList(handle, this.page, this.pageSize)
        .then(res => {
          this.loading = false;
          if (res.error === 0) {
            if (res.data.length) {
              this.messages = [...this.messages, ...res.data];
              this.page++;
            } else {
              this.finished = true;
            }
          } else {
            this.finished = true;
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          this.loading = false;
          this.finished = true;
          console.log('请求问题列表失败', error)
        })
    },
    toMessageDetailPage(orderId, oUserId) {
      this.$router.push({
        name: 'messageDetail',
        params: {
          orderId,
          oUserId,
        }
      });
    }

  }
}
</script>

<style lang="less" scoped>
  .header{
    font-size: 1.2rem;
    padding: .5rem 0;
    color: #000000;
    background-color: #f2f2f2;
    font-weight: bold;
  }
  .list{
    padding: 0 .8rem;
    .item{
      display: flex;
      border-bottom: 1px solid #cccccc;
      padding: .6rem 0;
      width: 100%;
      .image{
        flex: 0 0 auto;
        width: 3.5rem;
        height: 3.5rem;
        background-color: #666666;
        position: relative;
        img{
          width: 100%;
          height: 100%;
        }
        @numLength: 1rem;
        .num{
          position: absolute;
          width: @numLength;
          height: @numLength;
          line-height: @numLength;
          text-align: center;
          top: -@numLength / 2;
          right: -@numLength / 2;
          background: #ff0200;
          border-radius: 50%;
          color: #ffffff;
          font-size: .5rem;
          font-weight: bold;
        }

      }
      .info{
        flex: 1 1 auto;
        font-size: .8rem;
        padding-left: 16px;
        .top{
          display: flex;
          justify-content: space-between;
          padding: 0 0 .4rem;
          .customer-name{
            font-weight: bold;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 30vh;
            min-width: 0;
            flex: 1 1 auto;
          }
          .last-date{
            //white-space: nowrap;
            flex: 0 0 auto;
            padding-left: .8rem;
          }

        }
        .bottom {
          .title{
            text-align: left;
            color: #000000;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            height: 2rem;
            line-height: 1rem;
          }
        }

      }
    }
  }
</style>
